#csMenu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  #csMenu li {
    position: relative;
  }
  
  #csMenu a {
    display: block;
    padding: 30px;
    font-size: 18px;
    font-weight: bold;
    color: rgb(15, 83, 164);
    text-decoration: none;
  }
  
  #csMenu a:hover {
    background-color: #ccc;
  }
  
  #csMenu ul ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #f2f2f2;
  }
  
  #csMenu ul li:hover > ul {
    display: inherit;
  }
  
  #csMenu ul ul li {
    width: 200px;
    float: none;
    display: list-item;
    position: relative;
  }
  
  #csMenu ul ul a {
    padding: 17px 17px;
    color: #333;
  }
  
  #csMenu ul ul a:hover {
    background-color: #ccc;
  }
  
  #csMenu ul ul ul {
    position: absolute;
    left: 100%;
    top: 0;
  }

  .mui-1xnbq41-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    border-radius: 20px !important;
  }

  #csMenu .active {
    border-bottom: 2px solid rgb(27, 129, 239);
    padding-bottom: 15px;
    position: relative;
    top: 3px;
  }
  