/* Animation */
@keyframes scroll {
    0% {
        transform: translateX(0px);
    }

    100% {
        transform: translateX(calc(-350px * 12));
    }
}


/*  Styling  */
#logos-home-page .slider {
    height: auto;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
}

#logos-home-page .slider:before, #logos-home-page .slider:after {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100px;
    position: absolute;
    width: 350px;
    z-index: 2;
}

#logos-home-page .slider:after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

#logos-home-page .slider:before {
    left: 0;
    top: 0;
}

#logos-home-page .slide-track {
    animation: scroll 30s linear infinite;
    display: flex;
    width: calc(350px * 24);
justify-content: space-between;
}

#logos-home-page div.slide {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}