.nft {
  user-select: none;
  max-width: 100vw;
  margin: 0px auto;
  padding: 1.5rem;
  border: 1px solid #ffffff22;
  background-color: #282c34;
  background: linear-gradient(0deg, rgba(40, 44, 52, 1) 0%, rgba(17, 0, 32, 0.5) 100%);
  box-shadow: 0 7px 20px 5px #00000088;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  overflow: hidden;
  transition: .5s all;
}

.nft hr {
  width: 100%;
  border: none;
  border-bottom: 1px solid #88888855;
  margin-top: 0;
}

.nft ins {
  text-decoration: none;
}

.product-category .main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  row-gap: 5vh;
  column-gap: 5vw;
  width: 100%;
  padding: 1rem;
}

.nft .main .tokenImage {
  border-radius: .5rem;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.nft::before {
  position: fixed;
  content: "";
  box-shadow: 0 0 100px 100px #ffffff08;
  top: -10%;
  left: -100%;
  transform: rotate(-45deg);
  height: 60rem;
  transition: 1s all;
}

.nft:hover {
  border: 1px solid #ffffff44;
  box-shadow: 0 7px 50px 10px #000000aa;
  transform: scale(1.015);
  filter: brightness(1.1);
}

.nft:hover .tokenImage {
transition: .2s ease-in;
  transform: scale(1.05);
}

.nft:hover::before {
  filter: brightness(.9);
  top: -100%;
  left: 200%;
}

.nft .bg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: opacity(0.3);
  z-index: -10;
}

.nft .bg h1 {
  filter: blur(5px);
  color: white;
  /* animation: move 20s linear infinite; */
}

.search-container {
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-button {
  background-color: #ccc;
  border: none;
  padding: 10px;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.search-button i {
  color: #fff;
}

@keyframes move {
  0% {
    transform: translate(0px, 0px);
  }

  35% {
    transform: translate(0px, -50px);
  }

  50% {
    transform: translate(0px, 100px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@media (min-width: 900px) {
  .product-category .main {
    flex-direction: row;
  }

  .bg {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
}